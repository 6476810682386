import {
  AutoComplete,
  Button,
  Card,
  Col,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  EyeOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import { applySort, isDisableFilter } from '../../../utils/common.utils';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { forEach, isEmpty, isFinite } from 'lodash';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import { AgentDocument } from '../../../types/response/data/agents/agentDocument.type';
import { AgentOverview } from '../../../types/data/allAgents.type';
import { AgentService } from '../../../services/agent.service';
import { ColumnsType } from 'antd/es/table';
import EmptyState from '../../../components/common/emptyState/emptyState';
import { FilterType } from '../../../enums/filterType.enum';
import HierarchyView from './hierarchyView/hierarchyView';
import { IdConstants } from '../../../constants/id.constants';
import { PageConstants } from '../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import PaymentConfigModal from '../../../components/paymentConfig/paymentConfigModal';
import { PaymentTypeEnum } from '../../../enums/paymentType.enum';
import { PlausibleEventNameConstants } from '../../../constants/eventName.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { TableType } from '../../../enums/tableType.enum';
import { TooltipWrapper } from './agentOverview/paymentConfig';
import { adminStore } from '../../../stores/admin.store';
import agencyIcon from '../../../assets/icons/hierarcyViewAgencyIcon.svg';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { getNameString } from '../../../utils/name.utils';
import { getProducerName } from './agencyAgentOverview';
import { hierarchyViewStore } from '../../../stores/hierarchyViewStore';
import { observer } from 'mobx-react';
import penIcon from '../../../assets/icons/pen.svg';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { trackCustomEvent } from '../../../utils/trackingUtils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
enum ColumnName {
  INITIAL_PAYMENT = 'initialPaymentPayee',
  RENEWAL_PAYMENT = 'renewalPaymentPayee',
  PAYMENT_TYPE = 'paymentType',
  AUTO_RENEWAL = 'autoRenewal',
}

enum Tab {
  LOA = 'loa',
  GA = 'ga',
}

export type FilterCondition = 'equals' | 'contains' | 'startsWith' | 'endsWith';

function Agents() {
  const navigate = useNavigate();
  const [generalAgents, setGeneralAgents] = useState<AgentOverview[]>([]);
  const [displayPaymentConfigNullAgents, setDisplayPaymentConfigNullAgents] =
    useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [lineOnlyAgents, setlineOnlyAgents] = useState<AgentOverview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRadio, setSelectedRadio] = useState<Tab>(Tab.LOA);
  const [tableType, setTableType] = useState(TableType.LINE_ONLY_AGENTS);
  const [errorMessage, setErrorMessage] = useState('');
  const { getAccessTokenSilently } = useAuth();

  const [searchText, setSearchText] = useState('');
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false);
  const [prevSearchText, setPrevSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');

  const [agencyId, setAgencyId] = useState(
    hierarchyViewStore.currentSelectedAgencyId
  );
  const [paymentConfigModalVisibility, setPaymentConfigModalVisibility] =
    useState<boolean>(false);
  const [unconfiguredPaymentAgents, setUnconfiguredPaymentAgents] = useState<
    AgentOverview[]
  >([]);

  const [lineOnlyCount, setLineOnlyCount] = useState(0);
  const [generalCount, setGeneralCount] = useState(0);
  const [paymentUnconfiguredCount, setPaymentUnconfiguredCount] =
    useState<number>();
  const [currentAgents, setCurrentAgents] = useState<AgentOverview[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<AgentOverview[]>([]);
  const [isHoveringInitialPayment, setIsHoveringInitialPayment] = useState<
    boolean[]
  >(lineOnlyAgents.map((itr) => false));
  const [isHoveringRenewalPayment, setIsHoveringRenewalPayment] = useState<
    boolean[]
  >(lineOnlyAgents.map((itr) => false));
  const [isHoveringPaymentType, setIsHoveringPaymentType] = useState<boolean[]>(
    lineOnlyAgents.map((itr) => false)
  );
  const [isHoveringAutoRenewal, setIsHoveringAutoRenewal] = useState<boolean[]>(
    lineOnlyAgents.map((itr) => false)
  );
  const [isLoaRowClickable, setLoaRowClickable] = useState<boolean[]>(
    lineOnlyAgents.map((itr) => true)
  );

  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [sortValue, setSortValue] = useState({});
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const fetchFiltersInfoForAgents: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      if (selectedRadio === Tab.LOA) {
        const path = '/account/producer';
        setIsFilterInfoLoading(true);
        const token = await getAccessTokenSilently();
        if (token) {
          const response: any = await AgentService.getFilterDetails(
            token,
            pageSize,
            pageNumber,
            path,
            searchText
          );
          setSearchFilter(
            sortSearchTypesByPage(
              response?.data?.filter(
                (data: { filterType: string }) =>
                  data.filterType === FilterType.SEARCH
              ),
              PageConstants.HOME
            )
          );
          return response.data;
        }
      } else {
        if (selectedRadio === Tab.GA) {
          setIsFilterInfoLoading(true);
          const token = await getAccessTokenSilently();
          if (token) {
            const response: any =
              await AgentService.getFilterInfoForGeneralAgent(
                agencyId,
                token,
                requestBody
              );
            return response.data;
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    adminStore.agency && setAgencyId(adminStore.agency.id);
  }, [adminStore.agency]);

  useEffect(() => {
    setSelectedAgents([]);
    if (displayPaymentConfigNullAgents) {
      if (unconfiguredPaymentAgents)
        setLoaRowClickable(unconfiguredPaymentAgents.map((itr) => true));
      else setLoaRowClickable([]);
    } else setLoaRowClickable(lineOnlyAgents.map((itr) => true));
  }, [displayPaymentConfigNullAgents]);

  useEffect(() => {
    selectedRadio === Tab.LOA && agencyId && loadAgents(agencyId);
    selectedRadio === Tab.GA && agencyId && loadGeneralAgents(agencyId);
  }, [agencyId]);

  useEffect(() => {
    if (paymentUnconfiguredCount === 0) {
      setDisplayPaymentConfigNullAgents(false);
      loadAgents(agencyId);
    }
    if (
      unconfiguredPaymentAgents?.length === 0 &&
      pageNumber > 1 &&
      displayPaymentConfigNullAgents
    ) {
      setPageNumber((prev) => prev - 1);
    }
  }, [paymentUnconfiguredCount]);

  useEffect(() => {
    agencyId && loadAgents(agencyId);
  }, [queryText, searchType]);

  useEffect(() => {
    setIsHoveringPaymentType(lineOnlyAgents.map((itr) => false));
    setIsHoveringRenewalPayment(lineOnlyAgents.map((itr) => false));
    setIsHoveringInitialPayment(lineOnlyAgents.map((itr) => false));
  }, [lineOnlyAgents]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
    if (queryText !== prevSearchText && prevSearchText !== '') {
      if (displayPaymentConfigNullAgents) {
        loadAgents(agencyId, {
          searchTextProp: queryText || '',
          paymentConfig: true,
        });
        setPrevSearchText(queryText || '');
      } else {
        selectedRadio === Tab.LOA &&
          agencyId &&
          !isSearchLoading &&
          loadAgents(agencyId, { searchTextProp: queryText || '' });

        selectedRadio === Tab.GA &&
          agencyId &&
          !isSearchLoading &&
          loadGeneralAgents(agencyId, queryText || '');
        setPrevSearchText(queryText || '');
      }
    }
  }, [queryText]);

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      selectedRadio === Tab.LOA &&
        loadAgents(agencyId, {
          page: 1,
          paymentConfig: displayPaymentConfigNullAgents,
        });
      selectedRadio === Tab.GA && loadGeneralAgents(agencyId);
    }
  }, [requestBody]);

  const handleBulkChanges = () => {
    setCurrentAgents(selectedAgents);
  };

  const handleIndividualChanges = (agentId: string) => {
    if (displayPaymentConfigNullAgents && unconfiguredPaymentAgents) {
      const agent = unconfiguredPaymentAgents.find((itr) => itr.id === agentId);
      if (agent) {
        setCurrentAgents([agent]);
      }
    } else {
      const agent = lineOnlyAgents.find((itr) => itr.id === agentId);
      if (agent) {
        setCurrentAgents([agent]);
      }
    }
  };

  const handleMouseEnter = (rowIndex: number, columnName?: ColumnName) => {
    if (columnName === ColumnName.PAYMENT_TYPE) {
      const updatedIsHoveringCellArr = isHoveringPaymentType.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringPaymentType(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.INITIAL_PAYMENT) {
      const updatedIsHoveringCellArr = isHoveringInitialPayment.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringInitialPayment(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.RENEWAL_PAYMENT) {
      const updatedIsHoveringCellArr = isHoveringRenewalPayment.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringRenewalPayment(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.AUTO_RENEWAL) {
      const updatedIsHoveringCellArr = isHoveringAutoRenewal.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringAutoRenewal(updatedIsHoveringCellArr);
    }
  };

  const handleMouseLeave = (index: number, columnName?: ColumnName) => {
    if (columnName === ColumnName.PAYMENT_TYPE) {
      const updatedIsHoveringCellArr = [...isHoveringPaymentType];
      updatedIsHoveringCellArr[index] = false;
      setIsHoveringPaymentType(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.INITIAL_PAYMENT) {
      const updatedIsHoveringCellArr = [...isHoveringInitialPayment];
      updatedIsHoveringCellArr[index] = false;
      setIsHoveringInitialPayment(updatedIsHoveringCellArr);
    } else {
      const updatedIsHoveringCellArr = [...isHoveringRenewalPayment];
      updatedIsHoveringCellArr[index] = false;
      setIsHoveringRenewalPayment(updatedIsHoveringCellArr);
    }
  };

  const loadGeneralAgents = async (
    agencyId?: string,
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number
  ) => {
    setIsLoading(true);
    if (agencyId) {
      const accessToken = await getAccessTokenSilently();
      const response: void | PaginatedSuccessResponse<AgentDocument> =
        await AgentService.getFirstLevelDownlineAgents(
          agencyId,
          pageSizeProp || pageSize,
          pageNumberProp || pageNumber,
          searchTextProp !== undefined ? searchTextProp : searchText,
          accessToken
        ).catch((error) => {
          setErrorMessage('Error! Unable to fetch agents at the moment');
        });
      if (response?.data) {
        setGeneralCount(response.totalCount);
        const newAgents: AgentOverview[] = response.data.map(
          (agentDocument) => {
            return {
              name: agentDocument.name,
              id: agentDocument.id,
              npn: agentDocument.npn,
              agencyId: {
                id: agentDocument?.agencyId?.id,
                agencyName: agentDocument?.agencyId?.name,
              },
              expiringLicenses: agentDocument.count30,
              paymentConfig: {
                initialPaymentPayee:
                  agentDocument.paymentConfig?.initialPaymentPayee || '',
                renewalPaymentPayee:
                  agentDocument.paymentConfig?.renewalPaymentPayee || '',
                paymentType: agentDocument.paymentConfig?.paymentType,

                amountCap: agentDocument.paymentConfig?.amountCap || undefined,
                isAutoRenewalActive:
                  agentDocument?.paymentConfig?.isAutoRenewalActive,
              },
            };
          }
        );
        setGeneralAgents(newAgents);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadAgents(hierarchyViewStore.currentSelectedAgencyId);
  }, [hierarchyViewStore.currentSelectedAgencyId]);

  const loadAgents = async (
    agencyId: string,
    props?: {
      page?: number;
      size?: number;
      paymentConfig?: boolean;
      searchTextProp?: string;
    },
    sort?: object
  ) => {
    let errorTypeRequestCancel = false;
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently();
    const response = await AgentService.getAllExpiryCountForAgents(
      null,
      props?.size || pageSize,
      props?.page || pageNumber,
      props?.searchTextProp !== undefined
        ? props.searchTextProp
        : queryText || '',
      accessToken,
      props?.paymentConfig,
      { ...requestBody, ...(sort || sortValue || {}) },
      agencyId,
      searchType
    ).catch((error) => {
      if (error.code === 'ERR_CANCELED') {
        errorTypeRequestCancel = true;
      }

      setErrorMessage('Error! Unable to fetch agents at the moment');
    });

    if (response?.data) {
      const newAgents: any = response.data.map((agentDocument: any) => {
        return {
          name: agentDocument.name,
          id: agentDocument?.id!,
          npn: agentDocument.npn,
          agencyId: {
            id: agentDocument?.agencyId?.id,
            agencyName: agentDocument?.agencyName,
          },
          expiringLicenses: agentDocument.expiryLicensesCount,
          paymentConfig: {
            initialPaymentPayee:
              agentDocument.paymentConfig?.licenseInitialPayee || '',
            renewalPaymentPayee:
              agentDocument.paymentConfig?.licenseRenewalPayee || '',
            paymentType: agentDocument.paymentConfig?.paymentType,
            amountCap: agentDocument.paymentConfig?.amountCap || undefined,
            isAutoRenewalActive:
              agentDocument?.paymentConfig?.isAutoRenewEnabled ||
              agentDocument?.paymentConfig?.isAutoRenewalActive,
          },
          isLicenseProcessing:
            agentDocument?.processingFlags?.isLicenseProcessing,
          isAssignmentProcessing:
            agentDocument?.processingFlags?.isAssignmentProcessing,
        };
      });
      isFinite(response?.unconfiguredProducersCount) &&
        setPaymentUnconfiguredCount(response.unconfiguredProducersCount || 0);
      displayPaymentConfigNullAgents && setUnconfiguredPaymentAgents(newAgents);
      setlineOnlyAgents(newAgents);
      setUnconfiguredPaymentAgents(newAgents || []);
      setLineOnlyCount(response.totalCount);
      if (
        agencyId === adminStore.agency?.id &&
        searchText.length === 0 &&
        !props?.paymentConfig &&
        Object.keys(requestBody).length === 0
      ) {
        hierarchyViewStore.lineOnlyAgentsCount = response.totalCount;
      }
      if (!displayPaymentConfigNullAgents) {
        setLoaRowClickable(newAgents.map((itr: any) => true));
      }
    }

    if (!errorTypeRequestCancel) setIsLoading(false);
  };

  const onSearch = async () => {
    try {
      if (searchText === prevSearchText) return;
      setQueryText(searchText);
      setSearchLoading(true);
      if (displayPaymentConfigNullAgents) {
        await loadAgents(agencyId, {
          page: 1,
          size: pageSize,
          paymentConfig: true,
          searchTextProp: searchText,
        });
      } else if (selectedRadio === Tab.GA) {
        await loadGeneralAgents(agencyId, searchText);
      } else {
        await loadAgents(agencyId, {
          page: 1,
          size: pageSize,
          searchTextProp: searchText,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setPageNumber(1);
      setSearchLoading(false);
      setPrevSearchText(searchText);
    }
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    if (displayPaymentConfigNullAgents) {
      agencyId &&
        loadAgents(agencyId, {
          page: newPageNumber,
          size: newPageSize,
          paymentConfig: true,
        });
    } else if (selectedRadio === Tab.LOA) {
      agencyId &&
        loadAgents(agencyId, {
          page: newPageNumber,
          size: newPageSize,
        });
    } else if (selectedRadio === Tab.GA) {
      agencyId && loadGeneralAgents(agencyId);
    }
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
  };

  const redirect = (record: AgentOverview) => {
    appStateInfoStore.setHeader(getNameString(record.name));
    navigate(RouteConstants.agentOverview.path.replace(':agentId', record.id));
  };

  const GeneralAgentsColumns: ColumnsType<AgentOverview> = [
    {
      title: 'Producer',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: '200px',

      render: (text: string, record: AgentOverview) => (
        <div>
          <Row>
            <Typography.Paragraph
              style={{
                fontSize: '14px',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                color: '#000000',
              }}
            >
              <span
                className="cursor-pointer"
                onClick={() => {
                  redirect(record);
                }}
              >
                {getNameString(text)}
              </span>{' '}
            </Typography.Paragraph>
          </Row>
          <Row>
            <Typography.Paragraph
              style={{
                fontWeight: 700,
                fontSize: '10px',
                color: 'var(--secondary-color)',
                marginRight: '10px',
              }}
            >
              GA
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontWeight: 500,
                fontSize: '10px',
              }}
            >
              NPN: {record.npn}
            </Typography.Paragraph>
          </Row>
          <Row>
            <img
              src={agencyIcon}
              alt="agency"
              style={{
                color: '#000',
                width: '12px',
                height: '12px',
                marginRight: '7px',
              }}
            />
            <span
              style={{
                fontWeight: 500,
                fontSize: '10px',
                marginTop: '-1.5px',
              }}
            >
              {record?.agencyId?.agencyName}
            </span>
          </Row>
        </div>
      ),
      onCell: (record: AgentOverview) => {
        return {
          onClick: () => {
            appStateInfoStore.setHeader(getNameString(record.name));
            navigate(
              RouteConstants.agentOverview.path.replace(':agentId', record.id)
            );
          },
        };
      },
    },
    {
      title: 'Initial Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseInitialPayee',
      sorter: true,
      width: '225px',

      render: (text: any, record: AgentOverview) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
              record.id +
              '-general-agents'
            }
          >
            {record.paymentConfig?.initialPaymentPayee ? (
              getProducerName(record.paymentConfig?.initialPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Not configured
              </span>
            )}
          </Row>
        );
      },
    },
    {
      title: 'Renewal Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseRenewalPayee',
      width: '225px',
      sorter: true,

      render: (text: any, record: AgentOverview) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.RENEWAL_PAYMENT +
              record.id +
              '-general-agents'
            }
          >
            {record.paymentConfig?.renewalPaymentPayee ? (
              getProducerName(record.paymentConfig?.renewalPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Not configured
              </span>
            )}
          </Row>
        );
      },
    },
    {
      title: 'Auto Apply',
      dataIndex: 'autoRenewEnabled',
      key: 'autoRenewEnabled',
      width: '115px',

      render: (text: any, record: AgentOverview) => {
        return (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#0588ca',
              },
            }}
          >
            <Switch
              id={
                IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.AUTO_RENEWAL +
                record.id +
                '-general-agents'
              }
              checked={
                record.paymentConfig?.isAutoRenewalActive
                  ? record.paymentConfig?.isAutoRenewalActive
                  : false
              }
              disabled
            />
          </ConfigProvider>
        );
      },
    },
  ];
  const LineOnlyAgentsColumns: ColumnsType<AgentOverview> = [
    {
      title: 'Producer',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: '220px',
      render: (text: string, record: AgentOverview) => {
        return (
          <div>
            <Row>
              <Typography.Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'var(--primary-color)',
                  marginBottom: '0.5em',
                }}
              >
                {record.paymentConfig?.initialPaymentPayee &&
                record.paymentConfig?.renewalPaymentPayee &&
                record.paymentConfig?.paymentType ? (
                  <>
                    <div style={{ color: '#f00', fontWeight: 500 }}>
                      {(record?.processingFlags?.isLicenseProcessing ||
                        record?.processingFlags?.isAssignmentProcessing) && (
                        <ConfigProvider
                          theme={{ token: { colorTextLightSolid: '#000' } }}
                        >
                          <Tooltip
                            title={
                              record?.processingFlags?.isLicenseProcessing &&
                              record?.processingFlags?.isAssignmentProcessing
                                ? 'License(s) and Assignment(s) are currently being processed'
                                : record?.processingFlags?.isLicenseProcessing
                                ? 'License(s) currently being processed'
                                : 'Assignment(s) currently being processed'
                            }
                            color="#fff"
                            key="#fff"
                            style={{ color: 'var(--secondary-color)' }}
                          >
                            <InfoCircleOutlined
                              style={{ marginRight: '5px' }}
                            />
                          </Tooltip>
                        </ConfigProvider>
                      )}
                    </div>
                    <span
                      className="cursor-pointer"
                      onClick={() => redirect(record)}
                    >
                      {getNameString(text)}
                    </span>
                  </>
                ) : (
                  <div style={{ color: '#f00', fontWeight: 500 }}>
                    <ConfigProvider
                      theme={{ token: { colorTextLightSolid: '#000' } }}
                    >
                      <Tooltip
                        title={
                          'Payments not configured.' +
                          (record?.processingFlags?.isLicenseProcessing &&
                          record?.processingFlags?.isAssignmentProcessing
                            ? ' License(s) and Assignment(s) are currently being processed'
                            : record?.processingFlags?.isLicenseProcessing
                            ? ' License(s) currently being processed'
                            : record?.processingFlags?.isAssignmentProcessing
                            ? ' Assignment(s) currently being processed'
                            : '')
                        }
                        color="#fff"
                        key="#fff"
                        style={{ color: 'var(--secondary-color)' }}
                      >
                        <InfoCircleOutlined style={{ marginRight: '5px' }} />
                      </Tooltip>
                    </ConfigProvider>
                    <span
                      className="cursor-pointer"
                      onClick={() => redirect(record)}
                    >
                      {getNameString(text)}
                    </span>
                  </div>
                )}
              </Typography.Paragraph>
            </Row>
            <Row>
              <Typography.Paragraph
                style={{
                  fontWeight: 500,
                  fontSize: '10px',
                }}
              >
                NPN: {record.npn}
              </Typography.Paragraph>
            </Row>
          </div>
        );
      },
    },
    {
      title: 'Initial Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseInitialPayee',
      width: '190px',
      sorter: true,
      render: (text: any, record: AgentOverview, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
              record.id +
              '-line-only-agents'
            }
            style={{
              justifyContent: 'flex-start',
              cursor: 'pointer',
            }}
          >
            <img
              src={penIcon}
              style={{
                marginRight: 10,
                cursor: 'pointer',
              }}
              alt="pen"
              onClick={() => {
                if (selectedAgents.length > 0) {
                  handleBulkChanges();
                } else {
                  handleIndividualChanges(record.id);
                }
                setPaymentConfigModalVisibility(true);
              }}
            />
            {record.paymentConfig?.initialPaymentPayee ? (
              getProducerName(record.paymentConfig?.initialPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Select Payee
              </span>
            )}
          </Row>
        );
      },
      shouldCellUpdate: () => true,
      onCell: (record: AgentOverview, index) => {
        const onCellObject = {
          onMouseEnter: () => {
            handleMouseEnter(index || 0, ColumnName.INITIAL_PAYMENT);
          },
          onMouseLeave: () => {
            handleMouseLeave(index || 0, ColumnName.INITIAL_PAYMENT);
          },
          onClick: () => {},
        };
        if (
          selectedAgents.find((agent) => agent.id === record.id) ||
          (selectedAgents.length === 0 &&
            isHoveringInitialPayment[index as number])
        )
          onCellObject.onClick = () => {
            if (selectedAgents.length > 0) {
              handleBulkChanges();
            } else {
              handleIndividualChanges(record.id);
            }
            setPaymentConfigModalVisibility(true);
          };
        return onCellObject;
      },
    },
    {
      title: 'Renewal Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseRenewalPayee',
      width: '225px',
      sorter: true,
      render: (text: any, record: AgentOverview, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.RENEWAL_PAYMENT +
              record.id +
              '-line-only-agents'
            }
            style={{
              justifyContent: 'flex-start',
              cursor: 'pointer',
            }}
          >
            <img
              src={penIcon}
              style={{
                marginRight: 10,
                cursor: 'pointer',
              }}
              alt="pen"
              onClick={() => {
                if (selectedAgents.length > 0) {
                  handleBulkChanges();
                } else {
                  handleIndividualChanges(record.id);
                }
                setPaymentConfigModalVisibility(true);
              }}
            />
            {record.paymentConfig?.renewalPaymentPayee ? (
              getProducerName(record.paymentConfig?.renewalPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Select Payee
              </span>
            )}{' '}
          </Row>
        );
      },
      shouldCellUpdate: () => true,
      onCell: (record: AgentOverview, index) => {
        const onCellObject = {
          onMouseEnter: () => {
            handleMouseEnter(index || 0, ColumnName.RENEWAL_PAYMENT);
          },
          onMouseLeave: () => {
            handleMouseLeave(index || 0, ColumnName.RENEWAL_PAYMENT);
          },
          onClick: () => {},
        };

        if (
          selectedAgents.find((agent) => agent.id === record.id) ||
          (selectedAgents.length === 0 &&
            isHoveringRenewalPayment[index as number])
        )
          onCellObject.onClick = () => {
            if (selectedAgents.length > 0) {
              handleBulkChanges();
            } else {
              handleIndividualChanges(record.id);
            }
            setPaymentConfigModalVisibility(true);
          };
        return onCellObject;
      },
    },
    {
      title: 'Auto Apply',
      dataIndex: 'autoRenewEnabled',
      key: 'autoRenewEnabled',
      width: '115px',
      render: (text: any, record: AgentOverview, index: any) => {
        return (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#0588ca',
              },
            }}
          >
            <TooltipWrapper
              isDisabled={
                record.paymentConfig?.renewalPaymentPayee !== 'Agency' ||
                !record.paymentConfig?.paymentType
                  ? true
                  : selectedAgents.length > 0
                  ? !(
                      isLoaRowClickable[index] &&
                      selectedAgents.length > 0 &&
                      selectedAgents.every(
                        (agent) =>
                          agent.paymentConfig?.renewalPaymentPayee ===
                            'Agency' && agent.paymentConfig?.paymentType
                      )
                    )
                  : !isLoaRowClickable[index]
              }
              message={
                !record.paymentConfig?.renewalPaymentPayee &&
                !record.paymentConfig?.paymentType
                  ? 'Auto Apply cannot be modified as Renewal Type and Payment Configuration are not set.'
                  : record.paymentConfig?.renewalPaymentPayee === 'Agent'
                  ? 'Auto Apply cannot be modified as Renewal Type is set to Producer'
                  : !record.paymentConfig?.paymentType
                  ? 'Auto Apply cannot be modified as Payment Configuration is not specified.'
                  : 'Auto Apply cannot be modified'
              }
            >
              <Switch
                id={
                  IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.AUTO_RENEWAL +
                  record.id +
                  '-line-only-agents'
                }
                checked={
                  record.paymentConfig?.isAutoRenewalActive
                    ? record.paymentConfig?.isAutoRenewalActive
                    : false
                }
                disabled={
                  record.paymentConfig?.renewalPaymentPayee !== 'Agency' ||
                  !record.paymentConfig?.paymentType
                    ? true
                    : selectedAgents.length > 0
                    ? !(
                        isLoaRowClickable[index] &&
                        selectedAgents.length > 0 &&
                        selectedAgents.every(
                          (agent) =>
                            agent.paymentConfig?.renewalPaymentPayee ===
                              'Agency' && agent.paymentConfig?.paymentType
                        )
                      )
                    : !isLoaRowClickable[index]
                }
                onClick={() => {
                  if (
                    record.paymentConfig?.renewalPaymentPayee !== 'Agent' &&
                    selectedAgents.every(
                      (agent) =>
                        agent.paymentConfig?.renewalPaymentPayee === 'Agency' &&
                        agent.paymentConfig?.paymentType
                    )
                  ) {
                    if (selectedAgents.length > 0) {
                      handleBulkChanges();
                    } else {
                      handleIndividualChanges(record.id);
                    }
                    setPaymentConfigModalVisibility(true);
                  }
                }}
              />
            </TooltipWrapper>
          </ConfigProvider>
        );
      },
      onCell: (record: AgentOverview, index) => {
        const onCellObject = {
          onMouseEnter: () => {
            handleMouseEnter(index || 0, ColumnName.AUTO_RENEWAL);
          },
          onMouseLeave: () => {
            handleMouseLeave(index || 0, ColumnName.AUTO_RENEWAL);
          },
          onClick: () => {},
        };

        if (
          record.paymentConfig?.renewalPaymentPayee !== 'Agent' &&
          record.paymentConfig?.paymentType &&
          selectedAgents.every((agent) => {
            if (agent.paymentConfig?.renewalPaymentPayee !== 'Agent') {
              return false;
            }
          })
        ) {
          if (
            selectedAgents.find((agent) => agent.id === record.id) ||
            (selectedAgents.length === 0 &&
              isHoveringAutoRenewal[index as number])
          )
            onCellObject.onClick = () => () => {
              if (selectedAgents.length > 0) {
                handleBulkChanges();
              } else {
                handleIndividualChanges(record.id);
              }
              setPaymentConfigModalVisibility(true);
            };
        }

        return onCellObject;
      },
    },
    {
      title: 'Expiring in 30 days',
      dataIndex: 'expiringLicenses',
      key: 'expiryLicensesCount',
      width: '175px',
      sorter: true,

      render: (text: number, record: AgentOverview) => (
        <Row
          style={{ alignItems: 'center', justifyContent: 'center', gap: 24 }}
        >
          <div className="ml-auto">{text ? text : 0}</div>
          <Tooltip title="View Licenses">
            <Button
              size="middle"
              className="ml-auto"
              onClick={() => {
                navigate({
                  pathname: RouteConstants.agencyLicenses.path,
                  search: createSearchParams({
                    search: getNameString(record.name),
                  }).toString(),
                });
              }}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
        </Row>
      ),
    },
  ];
  const OtherLineOnlyAgentsColumns: ColumnsType<AgentOverview> = [
    {
      title: 'Producer',
      dataIndex: 'name',
      key: 'name',
      width: '220px',
      sorter: true,
      render: (text: string, record: AgentOverview) => (
        <div>
          <Row>
            <Typography.Paragraph
              style={{
                fontSize: '14px',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                color: record.paymentConfig?.initialPaymentPayee
                  ? 'var(--primary-color)'
                  : '#ff0000',
              }}
            >
              {(record.processingFlags?.isLicenseProcessing ||
                record.processingFlags?.isAssignmentProcessing ||
                !record?.paymentConfig?.initialPaymentPayee) && (
                <ConfigProvider
                  theme={{ token: { colorTextLightSolid: '#000' } }}
                >
                  <Tooltip
                    title={
                      !record?.paymentConfig?.initialPaymentPayee
                        ? 'Payments not configured.'
                        : record?.processingFlags?.isLicenseProcessing &&
                          record?.processingFlags?.isAssignmentProcessing
                        ? 'License(s) and Assignment(s) are currently being processed'
                        : record?.processingFlags?.isLicenseProcessing
                        ? 'License(s) currently being processed'
                        : 'Assignment(s) currently being processed'
                    }
                    color="#fff"
                    key="#fff"
                    style={{ color: 'var(--secondary-color)' }}
                  >
                    <InfoCircleOutlined style={{ marginRight: '5px' }} />
                  </Tooltip>
                </ConfigProvider>
              )}
              <span
                className="cursor-pointer"
                // onClick={() => {
                //   redirect(record);
                // }}
              >
                {getNameString(text)}
              </span>
            </Typography.Paragraph>
          </Row>
          <Row>
            <Typography.Paragraph
              style={{
                fontWeight: 700,
                fontSize: '10px',
                color: 'var(--secondary-color)',
                marginRight: '10px',
              }}
            >
              LOA
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontWeight: 500,
                fontSize: '10px',
              }}
            >
              NPN: {record.npn}
            </Typography.Paragraph>
          </Row>
          <Row>
            <img
              src={agencyIcon}
              alt="agency"
              style={{
                color: '#000',
                width: '12px',
                height: '12px',
                marginRight: '7px',
              }}
            />
            <span
              style={{
                fontWeight: 500,
                fontSize: '10px',
                marginTop: '-1.5px',
              }}
            >
              {record?.agencyId?.agencyName}
            </span>
          </Row>
        </div>
      ),
    },
    {
      title: 'Initial Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseInitialPayee',
      width: '190px',
      sorter: true,

      render: (text: any, record: AgentOverview, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
              record.id +
              '-other-line-only-agents'
            }
            style={{
              justifyContent: 'space-between',
            }}
          >
            {record.paymentConfig?.initialPaymentPayee ? (
              getProducerName(record.paymentConfig?.initialPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Payee not selected
              </span>
            )}
          </Row>
        );
      },
    },
    {
      title: 'Renewal Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseRenewalPayee',
      width: '200px',
      sorter: true,

      render: (text: any, record: AgentOverview, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.RENEWAL_PAYMENT +
              record.id +
              '-other-line-only-agents'
            }
            style={{
              justifyContent: 'space-between',
            }}
          >
            {record.paymentConfig?.renewalPaymentPayee ? (
              getProducerName(record.paymentConfig?.renewalPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Payee not selected
              </span>
            )}{' '}
          </Row>
        );
      },
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig',
      width: '170px',
      render: (text: any, record: AgentOverview, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.PAYMENT_TYPE +
              record.id +
              '-other-line-only-agents'
            }
            style={{
              justifyContent: 'space-between',
            }}
          >
            <div>
              {record.paymentConfig?.paymentType === PaymentTypeEnum.NA ? (
                <span style={{ color: '#92A69E', fontSize: '12px' }}>N/A</span>
              ) : record.paymentConfig?.paymentType === PaymentTypeEnum.CAP ? (
                '$ ' + record.paymentConfig?.amountCap
              ) : record.paymentConfig?.paymentType === PaymentTypeEnum.FULL ? (
                record.paymentConfig?.paymentType
              ) : (
                <span style={{ color: '#92A69E', fontSize: '12px' }}>
                  Payment Type not configured
                </span>
              )}
            </div>
          </Row>
        );
      },
    },
    {
      title: 'Auto Apply',
      dataIndex: 'autoRenewEnabled',
      key: 'autoRenewEnabled',
      width: '110px',
      render: (text: any, record: AgentOverview, index: any) => {
        return (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#0588ca',
              },
            }}
          >
            <Switch
              id={
                IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.AUTO_RENEWAL +
                record.id +
                '-other-line-only-agents'
              }
              checked={
                record.paymentConfig?.isAutoRenewalActive
                  ? record.paymentConfig?.isAutoRenewalActive
                  : false
              }
              disabled
            />
          </ConfigProvider>
        );
      },
    },
    {
      title: 'Expiring in 30 days',
      dataIndex: 'expiringLicenses',
      key: 'expiryLicensesCount',
      width: '75px',
      sorter: true,
      render: (text: number, record: AgentOverview) => (
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
          <div className="ml-auto">{text ? text : 0}</div>
          {/* <Tooltip title="View Licenses">
            <Button
              size="middle"
              className="ml-auto"
              onClick={() => {
                navigate({
                  pathname: RouteConstants.agencyLicenses.path,
                });
              }}
            >
              <EyeOutlined />
            </Button>
          </Tooltip> */}
        </Row>
      ),
    },
  ];

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_DISTRIBUTION_CHANNEL;
  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <div style={{ display: 'block', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
          marginRight: '10px',
        }}
      >
        <Row>
          <Button
            className="button primary-button"
            style={{ marginRight: '10px', width: '100px' }}
            onClick={() => {
              navigate({
                pathname: RouteConstants.uplines.path,
              });
            }}
          >
            Uplines
          </Button>
          <Button
            className="button primary-button"
            style={{ width: '100px' }}
            onClick={() => {
              navigate({
                pathname: RouteConstants.downlines.path,
              });
            }}
          >
            Downlines
          </Button>
        </Row>
      </div>

      <HierarchyView
        currentAgency={(value: string) => {
          setAgencyId(value);
        }}
      />

      <Card style={{ marginTop: 10 }}>
        <Row>
          <Typography.Paragraph
            style={{
              color: 'var(--primary-color)',
              marginTop: '10px',
              marginBottom: '10px',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            {tableType === TableType.GENERAL_AGENTS && (
              <span>General Producers</span>
            )}
            {tableType === TableType.LINE_ONLY_AGENTS && (
              <span>License Only Agents</span>
            )}
          </Typography.Paragraph>
        </Row>
        <Row
          justify="space-between"
          className="search"
          style={{ marginTop: '10px' }}
        >
          <Col flex="auto">
            <AutoComplete
              options={options}
              style={{ width: '100%' }}
              disabled={isSearchLoading || isLoading}
              value={getSearchDisplayText(
                searchType,
                displaySearchType,
                searchText
              )}
              onSelect={handleOnSelect}
              onChange={handleOnChange}
            >
              <Search
                id={IdConstants.SEARCH_INPUT + '-' + selectedRadio}
                placeholder="Search by Producers or NPN"
                size="middle"
                style={{
                  // width: '99%',
                  borderRadius: 1,
                  flexGrow: 1,
                  color: '#92A69E',
                  borderColor: 'var(--border-color)',
                  marginBottom: selectedRadio === Tab.GA ? '2%' : '0%',
                }}
                onSearch={handleOnSearch}
              />
            </AutoComplete>
          </Col>
          <div className="button-group">
            <Button
              id={IdConstants.FILTERS_BUTTON}
              loading={filterInfoLoading}
              icon={<FilterOutlined />}
              style={{
                marginLeft: '10px',
                background: 'var(--hint-primary-color)',
                color: 'white',
              }}
              onClick={() => {
                setIsFilterVisible(true);
                trackCustomEvent(
                  PlausibleEventNameConstants.FILTER_OPTION,
                  pageName
                );
              }}
              disabled={
                isDisableFilter(
                  tableType === TableType.GENERAL_AGENTS
                    ? generalAgents
                    : displayPaymentConfigNullAgents
                    ? unconfiguredPaymentAgents
                    : lineOnlyAgents,
                  requestBody
                ) ||
                filterInfoLoading ||
                isLoading
              }
            >
              Filters
            </Button>
          </div>
        </Row>
        <div style={{ marginTop: '10px' }}>
          <RenderFilterChip
            {...{ selectedFilters, removeFilter, clearFilters, pageName }}
          />
        </div>
        <Row
          style={{
            marginTop: '15px',
          }}
          justify="space-between"
        >
          {selectedRadio === Tab.LOA &&
          (lineOnlyAgents[0]?.agencyId?.id === adminStore.agency?.id ||
            Object.keys(requestBody).length !== 0) &&
          paymentUnconfiguredCount &&
          paymentUnconfiguredCount > 0 ? (
            <>
              <Typography.Paragraph
                className="reset-icon-size"
                style={{
                  color: 'var(--secondary-color)',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                <Tooltip
                  title={`The payment setup for  ${paymentUnconfiguredCount} ${
                    paymentUnconfiguredCount > 1 ? 'producers' : 'producer'
                  } is not complete. Please proceed with configuring their ${
                    paymentUnconfiguredCount > 1 ? 'payments' : 'payment'
                  }`}
                >
                  <InfoCircleOutlined
                    style={{ color: '#f00', marginRight: '5px' }}
                  />
                </Tooltip>
                Payments for {paymentUnconfiguredCount}{' '}
                {paymentUnconfiguredCount > 1
                  ? 'producers are '
                  : 'producer is '}
                not configured.
                {!displayPaymentConfigNullAgents && (
                  <Button
                    disabled={isLoading}
                    id={IdConstants.AGENTS.AGENT_OVERVIEW.VIEW_AGENTS}
                    style={{
                      fontSize: '14px',
                      color: '#000',
                      fontWeight: 400,
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => {
                      setPageNumber(1);
                      setDisplayPaymentConfigNullAgents(true);
                      setSearchText('');
                      setPrevSearchText('');
                      loadAgents(agencyId, {
                        page: 1,
                        size: pageSize,
                        paymentConfig: true,
                      });
                    }}
                  >
                    View Producer{paymentUnconfiguredCount > 1 ? 's' : ''}
                    <RightOutlined style={{ color: '#f00' }} />
                  </Button>
                )}
              </Typography.Paragraph>

              {displayPaymentConfigNullAgents && (
                <Typography.Paragraph
                  style={{
                    color: 'var(--secondary-color)',
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                >
                  <Button
                    disabled={isLoading}
                    style={{}}
                    size="middle"
                    onClick={() => {
                      setSearchText('');
                      agencyId &&
                        loadAgents(agencyId, {
                          page: 1,
                          size: pageSize,
                          searchTextProp: '',
                        });

                      setPageNumber(1);
                      setDisplayPaymentConfigNullAgents(false);
                    }}
                  >
                    Show All Producers
                  </Button>
                </Typography.Paragraph>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>

        <Row className="table">
          {tableType === TableType.GENERAL_AGENTS ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                marginBottom: '15px',
              }}
            >
              <Table
                loading={isLoading}
                style={{ width: '100%' }}
                columns={GeneralAgentsColumns}
                dataSource={generalAgents}
                scroll={{ y: 600 }}
                pagination={false}
                rowKey={(record) => record.id + record.agencyName}
                locale={{
                  emptyText() {
                    return isLoading ? null : (
                      <div
                        style={{
                          textAlign: 'center',
                          marginLeft: '20%',
                          marginTop: '5%',
                        }}
                      >
                        <EmptyState
                          image="empty data"
                          heading="No general producers to show yet"
                          content={
                            errorMessage
                              ? errorMessage
                              : "You're all set to get started with managing your producers. It looks like you're starting with a clean slate."
                          }
                        />
                      </div>
                    );
                  },
                }}
              />
              <Row justify="end">
                <Pagination
                  total={generalCount}
                  pageSize={pageSize}
                  current={pageNumber}
                  showSizeChanger={false}
                  onChange={onChange}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                />
              </Row>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                marginBottom: '15px',
              }}
            >
              <Table
                style={{ width: '100%' }}
                rowSelection={{
                  type: 'checkbox',
                  onChange(selectedRowKeys, selectedRows, info) {
                    setSelectedAgents(selectedRows);
                    if (displayPaymentConfigNullAgents) {
                      const rowClickable = [...isLoaRowClickable];
                      forEach(unconfiguredPaymentAgents, (a, index) => {
                        if (selectedRows.length > 0) {
                          if (selectedRows.findIndex((r) => r.id === a.id) >= 0)
                            rowClickable[index] = true;
                          else rowClickable[index] = false;
                        } else rowClickable[index] = true;
                      });
                      setLoaRowClickable(rowClickable);
                    } else {
                      const rowClickable = [...isLoaRowClickable];
                      forEach(lineOnlyAgents, (a, index) => {
                        if (selectedRows.length > 0) {
                          if (selectedRows.findIndex((r) => r.id === a.id) >= 0)
                            rowClickable[index] = true;
                          else rowClickable[index] = false;
                        } else rowClickable[index] = true;
                      });
                      setLoaRowClickable(rowClickable);
                    }
                  },
                  selectedRowKeys: selectedAgents.map((sa) => sa.id),
                }}
                rowKey={(record) => record.id + record.agencyName}
                locale={{
                  emptyText() {
                    return isLoading ? null : lineOnlyAgents &&
                      adminStore.agency &&
                      adminStore.agency?.id &&
                      lineOnlyAgents[0]?.agencyId &&
                      lineOnlyAgents[0].agencyId?.id ===
                        adminStore.agency?.id ? (
                      <div
                        style={{
                          textAlign: 'center',
                          marginLeft: '20%',
                          marginTop: '5%',
                        }}
                      >
                        <EmptyState
                          heading="No license only agents to show yet"
                          route={
                            RouteConstants.onboardProducer.path +
                            '?type=individual'
                          }
                          button
                          buttonText="Producer Onboarding"
                          content={
                            errorMessage
                              ? errorMessage
                              : "You're all set to get started with managing your producers. It looks like you're starting with a clean slate."
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: 'center',
                          marginLeft: '20%',
                          marginTop: '5%',
                        }}
                      >
                        <EmptyState
                          image="empty data"
                          heading="No license only agents to show yet"
                          content={
                            errorMessage
                              ? errorMessage
                              : "You're all set to get started with managing your producers. It looks like you're starting with a clean slate."
                          }
                        />
                      </div>
                    );
                  },
                }}
                columns={
                  lineOnlyAgents &&
                  adminStore.account &&
                  adminStore.agency?.id &&
                  lineOnlyAgents[0]?.agencyId &&
                  lineOnlyAgents[0].agencyId?.id === adminStore.agency?.id
                    ? LineOnlyAgentsColumns
                    : OtherLineOnlyAgentsColumns
                }
                dataSource={
                  displayPaymentConfigNullAgents
                    ? unconfiguredPaymentAgents
                    : lineOnlyAgents
                }
                loading={isSearchLoading || isLoading}
                scroll={{ y: 600 }}
                pagination={false}
                onChange={(pagination, filters, sorter) => {
                  const sort = applySort(sorter, (_, __, ___, sort) => {
                    loadAgents(
                      agencyId,
                      { paymentConfig: displayPaymentConfigNullAgents },
                      sort
                    );
                  });
                  setSortValue(sort);
                }}
              />
              <Row justify="end">
                <Pagination
                  total={
                    displayPaymentConfigNullAgents
                      ? paymentUnconfiguredCount
                      : lineOnlyCount
                  }
                  pageSize={pageSize}
                  current={pageNumber}
                  showSizeChanger={false}
                  onChange={onChange}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                />
              </Row>
            </div>
          )}
          <PaymentConfigModal
            agents={currentAgents ? currentAgents : undefined}
            visible={paymentConfigModalVisibility}
            handleVisibility={() => {
              setPaymentConfigModalVisibility(false);
              setCurrentAgents([]);
            }}
            successCallback={() => {
              loadAgents(agencyId, {
                paymentConfig: displayPaymentConfigNullAgents,
              });
            }}
          />
          <SimpleFilter
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setApplyFilter={() => {}}
            setRequestBody={setRequestBody}
            requestBody={requestBody}
            filterLoader={filterInfoLoading}
            visibility={isFilterVisible}
            setVisibility={() => {
              setIsFilterVisible(false);
            }}
            fetchFilters={() => fetchFiltersInfoForAgents()}
            clearFilters={() => {
              clearFilters();
            }}
            pageName={pageName}
            filterPage={PageConstants.ORGANIZATION}
          />
        </Row>
      </Card>
    </div>
  );
}

export default withRoles(observer(Agents), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
